@font-face {
  src: url(../../assets/fonts/FiraSans-Regular.ttf) !important;
  font-family: 'Fira Sans' !important;    
}

body {
    background-image: linear-gradient(
      50deg,
      hsl(0deg 0% 100%) 0%,
      hsl(0deg 0% 99%) 16%,
      hsl(0deg 0% 98%) 24%,
      hsl(0deg 0% 97%) 30%,
      hsl(0deg 0% 97%) 35%,
      hsl(0deg 0% 96%) 40%,
      hsl(0deg 0% 95%) 45%,
      hsl(0deg 0% 94%) 50%,
      hsl(0deg 1% 94%) 55%,
      hsl(0deg 2% 94%) 60%,
      hsl(0deg 3% 95%) 65%,
      hsl(0deg 4% 95%) 70%,
      hsl(0deg 6% 95%) 76%,
      hsl(0deg 7% 95%) 84%,
      hsl(0deg 8% 95%) 100%
    );
    /* background-color: #f9f9f9 !important; */
    background-size: 100%;
    width: 100%;
    font-family: 'Fira Sans', sans-serif !important;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body>label, span, button, th, td, figcaption, .card-header {
  user-select: none;
}

/*
.dark-theme
*/

body.dark-theme {
  background-color: #212121 !important;
  color: #EEE !important;
  filter: grayscale(0.5);
}

body.dark-theme .card, body.dark-theme .card, body.dark-theme .modal-content, body.dark-theme #sidebar, body.dark-theme #sidebar button {
  background-color: #212121 !important;
  color: #EEE !important;
  border-color: rgba(1, 1, 1, 0.1) !important;
  filter: grayscale(0.5) !important;
}

body.dark-theme .tabscontrol {
  background-color: rgb(83, 83, 83) !important;
  color: #EEE !important;
  border-color: rgba(1, 1, 1, 0.1) !important;
}

/**/

.btn, td {
  cursor: pointer;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: white !important;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  border: solid !important;
  background-color: black !important;
  font-family: ui-rounded;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
}

.bg-black {
  background-color: #111 !important;
}

.bg-black-0 {
  background-color: #000 !important;
}

.text-black {
  color: #000;
}

@keyframes blink_animation {
  100% {
    color: #dc3545;
  }
}

.blink {
  animation-name: blink_animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}

.switch {
  width: 45px;
  height: 25px;
  position: relative;
}

.switch__input {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.switch__label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: #E6EBF0;
  border-radius: 100px;
  transition: all .3s ease;
}

.switch__input~.switch__label .switch__icon {
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 50;
  border-radius: 50%;
  background: #FFF;
  transition: all .3s ease;
}

.switch__input:checked~.switch__label .switch__icon {
  left: calc(100% - 23px);
  background: #0275d8;
  transition: all .3s ease;
}

/* radio button */

.magic-radio {
  position: absolute;
  display: none;
}

.magic-radio[disabled] {
  cursor: not-allowed;
}

.magic-radio+label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
}

.magic-radio+label:hover:before {
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-name: hover-color;
}

.magic-radio+label:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  border: 1px solid #c0c0c0;
}

.magic-radio+label:after {
  position: absolute;
  display: none;
  content: '';
}

.magic-radio[disabled]+label {
  cursor: not-allowed;
  color: #e4e4e4;
}

.magic-radio[disabled]+label:hover, .magic-radio[disabled]+label:before, .magic-radio[disabled]+label:after {
  cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before {
  border: 1px solid #e4e4e4;
  animation-name: none;
}

.magic-radio[disabled]+label:before {
  border-color: #e4e4e4;
}

.magic-radio:checked+label:before {
  animation-name: none;
}

.magic-radio:checked+label:after {
  display: block;
}

.magic-radio+label:before {
  border-radius: 50%;
}

.magic-radio+label:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0275d8;
}

.magic-radio:checked+label:before {
  border: 1px solid #0275d8;
}

.magic-radio:checked[disabled]+label:before {
  border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled]+label:after {
  background: #c9e2f9;
}

/* FORM */

input {
  max-height: 38px;
}

.input-group-prepend>.input-group-text {
  max-height: 38px;
  width: 42px;
  max-width: 42px;
}

.input-group-prepend>.input-group-text>.fa {
  margin: 0 auto;
}

/* TABLES */

th.order {
  cursor: pointer;
}

table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  font-size: small;
}

th.order::before {
  content: "\21F5";
  border-radius: 10%;
  margin: 0 5px 0 0;
}


/* SCHEDULE */

.scheduling-badge {
  position: absolute;
  margin-top: -5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.scheduling-badge.left {
  margin-left: -10px;
}

.scheduling-badge.center-left {
  margin-left: 0px;
}

.scheduling-badge.center-right {
  margin-left: 10px;
}

.scheduling-badge.right {
  margin-left: 20px;
}

.scheduling-badge.danger {
  background-color: #E01F30 !important;
}

.scheduling-badge.success {
  background-color: #1AB394 !important;
}

.scheduling-badge.warning {
  background-color: #f0ad4e !important;
}

.scheduling-badge.info {
  background-color: #19e5e6 !important;
}

.scheduling-card {
  display: inline;
  border: 1px solid #000;
}

/* CUSTOM LABELS */

.custom-control-label {
  cursor: pointer;
}

.align-center-xy {
  height: 100vh;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-content-on-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#loading {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  z-index: 100000;
  background-color: lightgray;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading span {
  font-size: 50px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.no-padding {
  padding: unset;
}

/* ALERT MESSAGE */

.alert-message .message {
  text-transform: lowercase;
}

.alert-message .message::first-letter {
  text-transform: uppercase;
}

/* FORM MESSAGE */

.alert p {
  text-transform: lowercase;
}

.alert p::first-letter {
  text-transform: uppercase;
}

/* input range */

input[type="range"] {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: #666;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: #666;
}

.hideImageElement {
  animation-name: hide;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

@keyframes hide {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

/* Counter  */

.imageCol {
  position: relative !important;
  /* padding: 0 !important; */
}

.imgIndex::before {
  counter-increment: section;
  content: counter(section);
}

.iconIndexBorder.imgVertical {
  top: 5px;
  right: 5px;
}

.iconIndexBorder.imgHorizontal {
  top: 5px;
  right: 5px;
}

.iconIndexBorder {
  position: absolute;
  height: 15px;
  width: 15px;
  right: 4px;
  /* border-radius: 50%; */
  /* background-color: white; */
  padding: 1px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconIndex {
  position: relative;
  top: 1px;
  right: 5px;
  height: 22px;
  width: 30px;
  font-weight: bold;
  border-radius: 50%;
  padding: 0 6px;
  border: 1px solid white;
  background-color: white;
}

.imgSelected {
  position: absolute;
    top: 2px;
    right: 2px;
    font-weight: bold;
    border-radius: 50%;
    height: 23px;
    width: 23px;
    padding: 0 3px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.imgViewSelected {
  position: absolute;
    width: 23px;
    height: 23px;
    top: 18px;
    left: 18px;
    /* font-size: 12px; */
    font-weight: bold;
    border-radius: 50%;
    padding: 0 3px;
    border: 2px solid white;
    background-color: transparent;
    color: white;
}

.divImage {
  background-size: contain;
  width: 100% !important;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: center;
}

.color-black {
  color: #000000;
}

.answerer-report[contenteditable] {
  color: #000000;
  line-height: normal;
  height: auto !important;
}

.answer-prescription[contenteditable] {
  color: #000000;
  height: auto !important;
}

div[contenteditable="true"] {
  color: #000000;
}

div[contenteditable="true"]:focus {
  color: #000000;
}

.answer-report-label {
  color: #000000 !important;
}

.medication-label {
  color: #000000;
}

.bg-black {
  background-color: #000;
}

#livebackupContainer .divArmazenamento {
  position: relative;
  padding: 15px;
  border: 1px black dashed;
  border-radius: 15px;
  background-color: #fbfbfb;
  margin-top: 30px
}

#livebackupContainer .divArmazenamento h5.title {
  position: absolute;
  top: -17px;
  background: white;
  padding: 3px;
}

#resizer {
  cursor: col-resize;
  width: 10px;
}

#resizer .resizer-line {
  width: 2px;
  height: 100%;
  background-color: lightgrey;
}

#resizer:hover .resizer-line {
  background-color: #4C9AFF;
  opacity: 1;
}

/* @media(max-width: 900px) {
  #lbl_images_captured {
    font-size: 70%;
  }
}


@media(max-width: 1080px) {
  html {
    font-size: 10pt;
  }
  input {
    max-height: 32px;
  }
  .input-group-prepend>.input-group-text {
    height: 32px;
    max-height: 32px;
    width: 42px;
    max-width: 42px;
  }
  .switch {
    width: 35px;
    height: 20px;
    position: relative;
  }
  .switch__input~.switch__label .switch__icon {
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 3px;
    top: 3px;
    z-index: 50;
    border-radius: 50%;
    background: #FFF;
    transition: all .3s ease;
  }
  .switch__input:checked~.switch__label .switch__icon {
    left: calc(100% - 17px);
    background: #0275d8;
    transition: all .3s ease;
  }
} */


#img_imageview_image, #video_imageview_video, .capture-image{
  min-height: 96px;
  min-width: 96px;
  background-image: url('/images/images/no-image.png');
  background-color: #222;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.toast {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 350px;
  overflow: hidden;
  font-size: 1rem;
  background-color: rgba(255,255,255,.85);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  z-index: 99999;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .75rem .75rem;
  color: #6c757d;
  background-color: rgba(255,255,255,.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,.05);
}

.toast-body {
  padding: .75rem;
  background-color: rgba(247,247,247,.85);
}

.checkbox-element + .label-element.custom-control-inline {
  display: block !important;
  clear: both;
}

.label-element + .label-element.custom-control-inline {
  display: block !important;
  clear: both;
}
.table-hover tbody tr:hover {
  color: white;
  background-color: grey;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem;
}
.btn-warning:hover {
  color: white;
  background-color: #f0ad4e;
  border-color: gray;
  border-radius: 18px;
}
.btn-success {
  color: white;
  background-color: #1AB394;
  border-color: #1AB394;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-success:hover {
  color: white;
  background-color: #1AB394; 
  border-color: #f0ad4e;
  border-radius: 18px;
}
.btn-warning {
  color: white;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-success.focus, .btn-success:focus {
  color: #fff;
  background-color: #1AB370;
  border-color: #1AB394;
  box-shadow: 0 0 0 0.2rem rgba(72,180,97,.5);
  border-radius: 18px;
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  /* border-radius: 18px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 4px;
}
.btn-danger {
  color: #fff;
  background-color: #E01F30;
  border-color: #E01F30;
  border-radius: 18px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #0275d8;
  border-radius: 18px;
  font-size: 12px;
  height: 36px;
}
.btn-primary {
  color: #fff;
  background-color: #0275d8;
  border-color: #0275d8;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-primary:hover {
  color: white;
  background-color: #0270d1;
  /* border-color: lightgray; */
  box-shadow: whitesmoke;
  border-radius: 18px;
}
.btn-outline-dark {
  color: #343a40;
  border-color: grey;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-info:hover {
  color: #fff;
  /* background-color: #138496; */
  border-color: #117a8b;
  border-radius: 18px;
}
.btn-info {
  color: #fff;
  background-color: #00cccc; 
  border-color: #00cccc;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-outline-info {
  color: #19e5e6;
  border-radius: 18px;
  border-color: #00cccc;
}
.nav-pills .nav-link:hover {
  color: black;
  background-color: lightgrey;
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  border-radius: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-outline-primary {
  color: #0275d8;
  border-color: #0275d8;
  border-radius: 18px;
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
  border-radius: 18px;
}
[contenteditable='true'] {
  caret-color: #f0ad4e;
}
.input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  height: 26px;
  font-size: 12px;
}
.input-group.has-validation>.input-group-append:nth-last-child(n+3)>.btn, .input-group.has-validation>.input-group-append:nth-last-child(n+3)>.input-group-text, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.btn, .input-group:not(.has-validation)>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 26px;
}
a {
  color: #0275d8;
  text-decoration: none;
  background-color: transparent;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav-pills .nav-link {
  border-radius: 18px;
  height: 36px;
}
.bg-primary {
  background-color: #0275d8 !important;
}
.alert-warning {
  color: #856404;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: white;
  text-decoration: none;
}
.alert-dismissible .close {
  position: absolute;
  top: -11px;
  right: 0;
  padding: .75rem 1.25rem;
  color: white;
}
.warning {
  background-color: #f0ad4e !important;
  color: white;
}
.card-header {
  padding: .75rem 1.25rem;
  font-size: 1rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
  text-transform: uppercase;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
  vertical-align: middle;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  font-family: 'Fira Sans';
  font-size: 14px;
}
.small, small {
  font-size: 90%;
  font-weight: 400;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
dd {
  margin-bottom: 0.2rem;
  margin-left: 0;
  font-size: 13px;
}
.form-group {
  margin-bottom: 8px;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1020;
}
.switch {
  width: 45px;
  height: 25px;
  position: relative;
}

.switch__input {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.switch__label {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background: #E6EBF0;
  border-radius: 100px;
  transition: all .3s ease;
}

.switch__input~.switch__label .switch__icon {
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 50;
  border-radius: 50%;
  background: #FFF;
  transition: all .3s ease;
}

.switch__input:checked~.switch__label .switch__icon {
  left: calc(100% - 23px);
  background: #2B8DFF;
  transition: all .3s ease;
}
.form-control::-webkit-input-placeholder {
  color: darkgrey !important;
  text-decoration: none;
  
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 3em !important;
  background: initial;
  background-color: #1AB394 !important;
  color: #fff;
  font-size: 1.0625em !important;
}
.swal2-styled.swal2-cancel {
  border: 0 !important;
  border-radius: 3em !important;
  background: initial;
  background-color: #f0ad4e !important; 
  color: #fff;
  font-size: 1.0625em !important;
}    
.btn-group-sm>.btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 18px;
  padding-top: 3px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bi::before, [class^="bi-"]::before, [class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-size: 17px;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ril__toolbar {
    top: 30px;
    height: 50px;
    z-index: 99;
    position: absolute;
}

.text-primary {
  color: #0275d8 !important;
}

.text-warning {
  color: #f0ad4e !important;
}
.text-info {
  color: #19e5e6 !important;
}

.rdw-editor-toolbar {
  background: whitesmoke;
  flex-wrap: wrap;
  font-size: 12px;
  -webkit-user-select: none;
  user-select: none;
  justify-content: space-between;
}
.rdw-option-wrapper {
  min-width: 15px !important;
  height: 15px !important;
  margin: 0 4px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  text-transform: capitalize;
  font-family: 'Fira Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);
  order: 1px solid #F1F1F1;
  padding: 1px;
}